<template>
	<el-container>
		<el-aside :style="isActive == true ? 'width: 64px !important;' : 'width: 200px !important;'">
			<AsideMenu />
		</el-aside>
		<el-container>
			<el-footer>
				<div class="top-nav2" :style="isActive == true ? 'left: 64px;' : 'left: 200px;'">
					<MenuType></MenuType>
					<div style="position: absolute;right: 0;height: 50px;display: flex;align-items: center;padding-right: 24px;">
						<HeaderAvatar />
					</div>
				</div>
			</el-footer>
			<el-header>
				<div class="top-nav" :style="isActive == true ? 'left: 64px;' : 'left: 200px;'">
					<!-- <HeaderNavbtn/> -->
					<HeaderTab />
				</div>
			</el-header>
			<el-main>
				<transition name="fade-transform" mode="out-in" v-if="userInfo && role=='client'">
				  <router-view v-slot="{ Component }" >
				      <keep-alive>
				        <component :is="Component"
				                   v-if="$route.meta.keepAlive"
				                   :key="$route.path" />
				      </keep-alive>
				      <component :is="Component"
				                 v-if="!$route.meta.keepAlive" />
				  </router-view>
				</transition>
				<div class="errPage-container"  v-if="!userInfo || role!=='client'">
				  <!-- <el-row>
				    <el-col :span="12">
				      <h1 class="text-jumbo text-ginormous">
				        401错误!
				      </h1>
				      <h2>您没有访问权限！</h2>
				      <h6>对不起，您没有访问权限，请不要进行非法操作！您可以返回主页面</h6>
				      <el-button icon="arrow-left" class="pan-back-btn" @click="back">
				        返回登录页
				      </el-button>
				    </el-col>
				    <el-col :span="12">
				      <img :src="errGif" width="313" height="428" alt="Girl has dropped her ice cream.">
				    </el-col>
				  </el-row> -->
				  <a-spin class="demo-loading-container" />
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import HeaderTab from "./components/Header/HeaderTab";
	import HeaderAvatar from "./components/Header/HeaderAvatar";
	import AsideMenu from "./components/Aside/AsideMenu.vue";
	import MenuType from "./components/Header/menuType.vue";
	import Cookies from "js-cookie";
	import errGif from '@/assets/401_images/401.gif'
	import newFile from '@/assets/401_images/new_file.json'
	import {
		changeKeywords,
		changeDescription,
		changeTitle
	} from '@/utils/brower.js'
	 export default {
	       name: "Index",
	       components:{
	           AsideMenu,
			   HeaderTab,
			   HeaderAvatar,
			   MenuType
	       },
		   data(){
			   return {
				   isActive:true,
				   userInfo:null,
				   errGif: errGif + '?' + +new Date(),
				   role:null,
			   }
		   },
		   
		   created() {
				this.isActive = this.$store.state.Layout.isActive;
				this.userInfo = Cookies.get("userInfo2")
				this.role = Cookies.get("role2")
				// console.log(this.role)
				changeTitle('深圳市格物思信息科技有限公司 官网-成为中国最值得信赖的IC元器件、PCB、SMT科技贸易服务商')
				changeKeywords('深圳格物思、元器件、PCB、SMT等一站式PCBA服务')
				changeDescription('深圳市格物思信息科技有限公司成立于2009年，目前在国内设立多家分公司（深圳芯航国际电子有限公司，北京格物思电子有限公司，武汉格物思信息科技有限公司等），并在香港、深圳、德国、美国等地设立仓储物流中心；全国设有两大生产基地：安徽广德产业基地和浙江诸暨视觉智能产业园。目前已为欧洲、亚洲的客户提供涵盖元器件、PCB、SMT等一站式PCBA服务。利用互联网、物联网、云技术对电子制造业细分行业全价值链进行深度的改造，采用分析行业共同需求、集中采购、拉式补货的备货方式，帮助工厂 式，帮助工厂客户按客户需要进行生产，实现真正意义上的VMI模式。')
				
				// changeTitle('深圳格物思电子有限公司 官网-成为中国最值得信赖的IC元器件、PCB、SMT科技贸易服务商')
				// changeKeywords('深圳格物思、元器件、PCB、SMT等一站式PCBA服务')
				// changeDescription('深圳格物思电子有限公司成立于2009年，目前在国内设立多家分公司（深圳格物思电子有限公司，北京格物思电子有限公司，武汉格物思信息科技有限公司等），并在香港、深圳、德国、美国等地设立仓储物流中心；全国设有两大生产基地：安徽广德产业基地和浙江诸暨视觉智能产业园。目前已为欧洲、亚洲的客户提供涵盖元器件、PCB、SMT等一站式PCBA服务。利用互联网、物联网、云技术对电子制造业细分行业全价值链进行深度的改造，采用分析行业共同需求、集中采购、拉式补货的备货方式，帮助工厂 式，帮助工厂客户按客户需要进行生产，实现真正意义上的VMI模式。')
				//layout打包位置
				if(newFile.newFileType==false){
					if(!this.userInfo || this.role!=='client'){
						this.$message.error('暂未登录请进行登录');
						this.$router.push({
							path: '/login',
						})
					}
				}
		   },
		   
		   mounted() {
				if (window.performance.navigation.type === 1) {
				      // console.log('页面被刷新')
				    } else {
				    // 这里可以写调用什么方法
				      // console.log('页面首次被加载')
					  location.reload();
				    }
		   },
		   
		   watch:{
			   isActive1(val){
			   	this.isActive = val;
			   }
		   },
		   
		   computed:{
			   isActive1(){
			   	return this.$store.state.Layout.isActive;
			   }
		   },
		   
		   methods:{
			   back() {
			     this.$router.push({ path: '/login' })
			   }
		   }
	   }
</script>

<style lang="less">
	
	.el-container{
		background: #f0f2f5;
		height: 100%;
	}
	
	.el-header {
		height: 34px !important;
	}

	.el-aside {
		/* width: 200px !important; */
	}
	
	.el-footer {
		padding: 0px !important;
	}

	.el-main {
		/* padding: 64px !important; */
		/* min-width: 1280px; */
	}

	#menubar {
		position: fixed;
		left: 0;
		z-index: 999;
	}

	#menubar .el-overlay {
		left: 64px !important;
	}

	#menubar .el-drawer {
		min-width: 176px;
	}

	.top-nav {
		position: fixed;
		top: 50px;
		right: 0px;
		z-index: 998;
		padding: 0px 24px;
		transition: all 0.6 ease;
	}

	.top-nav {
		display: flex;
		background-color: #fff;
		align-items: center;
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
	}
	
	.top-nav2{
		position: fixed;
		top: 0px;
		right: 0px;
		z-index: 998;
		height: 50px;background: #f6f7fb;
	}
	
	.errPage-container {
	  height:100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  background: #fff;
	}
	
	.pan-back-btn {
	  background: #008489;
	  color: #fff;
	  border: none!important;
	  margin-top:20px;
	}
	.pan-gif {
	  margin: 0 auto;
	  display: block;
	}
	.pan-img {
	  display: block;
	  margin: 0 auto;
	  width: 100%;
	}
	.text-jumbo {
	  font-size: 60px;
	  font-weight: 700;
	  color: #484848;
	}
	.list-unstyled {
	  font-size: 14px;
	}
	
	.list-unstyled li {
	  padding-bottom: 5px;
	}
	.list-unstyled a {
	  color: #008489;
	  text-decoration: none;
	}
	.demo-loading-container {
		text-align: center;
		  border-radius: 4px;
		  margin-bottom: 20px;
		  padding: 30px 50px;
		  margin: 20px 0;
		  height: 370px;
	}
</style>
