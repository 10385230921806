<template>
	<div>
		<Hamburger class="hamburger-container" :is-active="isActive" @toggleClick="toggleSideBar"></Hamburger>
	</div>
</template>

<script>
	import Hamburger from '@/components/Hamburger'
	export default {
		components:{
			Hamburger
		},
		data(){
			return {
				isActive:true,
			}
		},
		
		created() {
			// console.log('vuex',this.$store)
			this.isActive = this.$store.state.Layout.isActive;
		},
		
		mounted() {
			
		},
		
		watch:{
			isActive1(val){
				this.isActive = val;
			}
		},
		
		computed:{
			isActive1(){
				return this.$store.state.Layout.isActive;
			}
		},
		
		methods:{
			toggleSideBar:function(){
				if(this.isActive == false){
					this.$store.dispatch("setIsActive",{
						isActive:true
					})
				}else{
					this.$store.dispatch("setIsActive",{
						isActive:false
					})
				}
				// console.log('vuex',this.$store.state.Layout.isActive)
			}
		}
	}
</script>

<style lang="less" scoped>
	.hamburger-container {
	  line-height: 46px;
	  height: 100%;
	  float: left;
	  cursor: pointer;
	  transition: background .3s;
	  -webkit-tap-highlight-color:transparent;
	
	  &:hover {
	    background: rgba(0, 0, 0, .035)
	  }
	}
</style>