<template>
	<div id="menubar">
		<el-scrollbar height="calc(100vh)">
			<div class="aside-flex" style="height:calc(100vh);">
				<div>
					<!-- <div class="logo pointer" @click="goHome">
						<el-image :src="require('@/assets/image/common/color-logo.png')"></el-image>
					</div> -->
					<logo :collapse="isCollapse"></logo>
					<el-menu :default-active="activeMenu" :default-openeds="openeds" class="el-menu-vertical-demo" @open="handleOpen" background-color="#f6f7fb" active-text-color="#f2ab15" text-color="#000" @close="handleClose" :collapse="isCollapse">
					  <menu-tree :subMenuList="meunList" :mode="mode"></menu-tree>
					</el-menu>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
	import menuTree from '@/business/layout/components/Aside/menu.vue'
	import logo from '@/business/layout/components/Header/Logo.vue'
	import Cookies from "js-cookie";
	export default {
		components:{
			menuTree,
			logo
		},
		data() {
			return {
				mode: "horizontal",
				activeIndex: '',
				isCollapse: false,
				routerList:null,//路由列表
				meunList:null,
				openeds:["/order/orderManagement","/order/userInfo"]
			}
		},

		created() {
			let roleList=[]
			var role = Cookies.get("role2")
			this.meunList = JSON.parse(sessionStorage.getItem("meunList")) || null
			// console.log("$router",this.$router.options.routes)
			this.isCollapse = this.$store.state.Layout.isActive
			// console.log("1111",this.meunList)
			let data = []
			this.$router.options.routes.map(res=>{
				if(res.path=="/order"){
					res.children.map(res2=>{
						data.push(res2)
					})
				}
			})
			this.meunList = data
			
		},

		mounted() {
			
		},

		watch: {
			isActive1(val){
				this.isCollapse = val;
			}
		},

		computed: {
			activeMenu() {
			    const route = this.$route;
			    const { meta, path } = route;
			    // if set path, the sidebar will highlight the path you set
				// console.log(route)
			    if (meta.activeMenu) {
			        return meta.activeMenu;
			    }
			    return path;
			},
			
			isActive1(){
				return this.$store.state.Layout.isActive;
			}
		},

		methods: {
			handleOpen(key, keyPath) {
				// console.log("handleOpen",key, keyPath);
			},
			handleClose(key, keyPath) {
				// console.log(key, keyPath);
			}
		}
	}
</script>

<style scoped>
	.meun-hide {
		display: none;
	}

	.text {
		text-decoration: none;
		color: #303133;
		font-size: 14px;
		padding: 8px 16px;
		border-radius: 4px;
		cursor: pointer;
		transition: background-color 0.3s, color 0.3s;
		display: flex;
	}

	.text:hover {
		background-color: #f5f7fa;
	}

	.item .active {
		color: #df5a00;
		background-color: #fff9f4;
	}

	#menubar {
		z-index: 999;
	}

	.el-menu {
		background-color: #f5f7fa;
		border-right: 0;
	}

	/*过渡动画*/

	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo .el-image {
		height: 28px;
		width: 28px;
		margin-top: 16px;
	}

	.aside-flex {
		background-color: #f6f7fb;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-right: solid 1px #e6e6e6;
		transition: all 0.3 ease;
	}
	
	.el-menu-vertical-demo:not(.el-menu--collapse) {
	    width: 200px;
	  }
</style>
