<template>
	<div class="header-right-content">
		<div class="avatar">
			<el-dropdown>
				<span class="el-dropdown-link"  style="display: flex;align-items: center;">
					<el-avatar :src="image"></el-avatar>
					<span class="user-name">{{userInfo.contacttel}}</span>
				</span>
				<template #dropdown>
					<el-dropdown-menu>
						<!-- <el-dropdown-item @click="">个人中心</el-dropdown-item> -->
						<el-dropdown-item @click.native="exit">退出</el-dropdown-item>
						<!-- <el-dropdown-item @click="">清缓存</el-dropdown-item> -->
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	export default {
		data(){
			return {
				image: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
				name:"test",
				userInfo:null,//个人信息
			}
		},
		
		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
		},
		
		mounted() {
			
		},
		
		watch:{
			'$route'(to,from){
				if(to.fullPath=="/login"){
					window.location.reload();
				}
			}
		},
		
		computed:{
			
		},
		
		methods:{
			//--------------退出-----------------------
			exit:function(){
				sessionStorage.removeItem("setTabs")
				Cookies.remove('role');
				sessionStorage.removeItem("meunList")
				Cookies.remove('userInfo');
				this.$store.dispatch("setXlsxName", {
				    xlsxName: null,
				});
				this.$store.dispatch("setBomSize", {
				    bomSize: null,
				});
				this.$store.dispatch("setPlainOptions", {
				    plainOptions: null,
				});
				this.$store.dispatch("setXlsxDataList", {
				    xlsxDataList: [],
				});
				this.$store.dispatch("setBomDataList", {
				    bomDataList: [],
				});
				this.$store.dispatch("setBomForm", {
				    bomForm: null,
				});
				this.$router.push({
					path: '/login',
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.avatar {
		padding: 0px 12px;
		line-height:34px;
		display: flex;
		align-items: center;
	}
	.header-right-content{
		display: flex;
		align-items: center;
	}
	.header-right-content .el-avatar{
		width:24px;
		height: 24px;
	}
	.user-name{
		font-size: 13px;
		color: #000;
		margin-left: 8px;
	}
</style>